import { Request } from "./Request";
import { Info } from '@/store/modules/App'

class AppService {
  async getUpdateInfo() {
    return await Request.create<Info>({
      url: `/verify-update/`,
    })
  }
}

export { AppService }