import { VuexModule, Module, Mutation } from "vuex-module-decorators";
import translate from '../../../translate.json'

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'TranslateModule'
})
class TranslateModule extends VuexModule {
  messages: any = translate.ru

  @Mutation
  setMessages(data: any) {
    this.messages = data
  }
}

export { TranslateModule }