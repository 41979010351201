import { Player } from "./Player"
import { UserDTO } from '@/store/entities/User';
import { GameDifficulty } from '@/store/entities/Game';


type RoomData = {
  [K in keyof Omit<Room, 'addPlayer'>]: Room[K]
}

enum RoomModes {
  BASIC = 1,
  TEAM = 2
}

interface Winner {
  id: string
  records: {
    sessionId: string
  }[]
}
class RoomDTO {
  _v?: number
  id: string
  ownerId: string
  timer: number
  cardsCount: number
  difficulty: GameDifficulty
  isPlaying: boolean
  mode: RoomModes = RoomModes.BASIC
  players: UserDTO[] = []
  winners: Winner[] = []
  blocked: string[]
  
  // TODO
  constructor({ id, ownerId, players, winners, timer, isPlaying, difficulty, cardsCount = 9, mode, blocked }: { id: string, ownerId: string, players: UserDTO[], winners: Winner[], timer: number, isPlaying: boolean, difficulty: GameDifficulty, cardsCount: number, mode: RoomModes, blocked: string[] }) {
    this.id = id
    this.ownerId = ownerId
    this.players = players
    this.winners = winners
    this.timer = timer
    this.mode = mode
    this.cardsCount = cardsCount
    this.isPlaying = isPlaying
    this.difficulty = difficulty
    this.blocked = blocked
  }
}

class Room {
  id: string
  ownerId: string
  players: Player[] = []
  timer = 120
  cardsCount = 9
  difficulty: GameDifficulty = GameDifficulty.EASY
  mode: RoomModes = RoomModes.BASIC
  isPlaying = false
  winners: Winner[] = []

  blocked: string[] = []

  constructor({ id, ownerId, isPlaying, timer, players }: RoomDTO) {
    this.id = id
    this.ownerId = ownerId
    this.isPlaying = isPlaying
    if (players) {
      this.players = players.map(p => new Player(p))
    }
    if (timer) {
      this.timer = timer
    }
  }

  addPlayer(player: Player) {
    const hasOwner = this.players.find(p => p.id === this.ownerId)
    if (!hasOwner) {
      this.ownerId = player.id
    }
    this.players.push(player)
  }
}

export { Room, RoomDTO, RoomModes, Winner, RoomData }