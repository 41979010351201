import { RoomData } from '@/store/entities/Room';
import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { Room, RoomDTO } from '../entities/Room';
import { Game, GameDTO, GameModes } from '../entities/Game';
import { Player, PlayerStatuses } from '../entities/Player'
import { Card } from '../entities/Card';
import { GamePlayer } from '@/store/entities/Player';
import { userModule } from '../index';

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'SystemModule'
})
class SystemModule extends VuexModule {
  room: Room | null = null
  game: Game | null = null
  publicRooms: RoomData[] = []
  loading = false

  @Mutation
  setPublicRooms(rooms: RoomData[]) {
    this.publicRooms = rooms
  }

  @Mutation
  createRoom({ roomData }: { roomData: RoomDTO }) {
    const room = new Room(roomData)
    this.room = room
  }

  @Mutation
  updateRoom({ roomData }: { roomData: RoomDTO }) {
    const hasRoom = this.room

    if (!hasRoom) {
      // this.createRoom({ roomData })
      const room = new Room(roomData)
      this.room = room
    }

    const room = this.room

    if (!room) return
    const oldPlayers = room.players.map(p => p)
    room.players = roomData.players.map(player => {
      const hasPlayer = oldPlayers.find(p => p.id === player.id)
      if (hasPlayer) {
        return new Player({
          ...hasPlayer,
          color: player.color,
          status: player.status || hasPlayer.status,
          name: userModule.id === player.id ? hasPlayer.name : player.name,
          ready: player.ready,
          sleep: player.sleep
        })
      }
      else return new Player(player)
    })

    room.isPlaying = roomData.isPlaying
    room.mode = roomData.mode
    room.difficulty = roomData.difficulty
    room.timer = roomData.timer
    room.cardsCount = roomData.cardsCount
    room.ownerId = roomData.ownerId
    room.winners = roomData.winners
    room.blocked = roomData.blocked
  }

  @Mutation
  updateRoomPlayersSleep(playersSleep: { id: string, sleep: number }[]) {
    this.room?.players.forEach(player => {
      const sleepRecord = playersSleep.find(p => p.id === player.id)
      if (sleepRecord) {
        player.sleep = sleepRecord.sleep
      }
    })
  }

  @Mutation
  createGame(gameId: string) {
    if (this.room) {
      const game = new Game(gameId, this.room)
      this.game = game
    }
  }

  @Mutation
  updateGame({ gameData }: { gameData: GameDTO, force?: boolean }) {
    if (this.room) {
      if (!this.game) {
        const game = new Game(gameData.id, this.room)
        this.game = game
      }

      this.game.timer = gameData.timer

      if (gameData.sessionId !== undefined) {
        this.game.sessionId = gameData.sessionId
      }
      
      if (gameData.hint !== undefined) {
        this.game.hint = gameData.hint
      }

      if (gameData.currentPlayerId !== undefined) {
        this.game.currentPlayerId = gameData.currentPlayerId
      }

      if (gameData.nextPlayerId !== undefined) {
        this.game.nextPlayerId = gameData.nextPlayerId
      }

      if (gameData.mode !== undefined) {
        this.game.mode = gameData.mode
      }

      if (gameData.round !== undefined) {
        this.game.round = gameData.round
      }

      if (gameData.status !== undefined) {
        this.game.status = gameData.status
      }

      if (gameData.extra !== undefined) {
        this.game.extra = gameData.extra
      }

      if (gameData.isSuperTurn !== undefined) {
        this.game.isSuperTurn = gameData.isSuperTurn
      }

      if (gameData.difficulty !== undefined) {
        this.game.difficulty = gameData.difficulty
      }
      
      if (gameData.statuses !== undefined) {
        this.game.statuses = gameData.statuses
        console.log(this.game.statuses)
      }
      
      if (gameData.tmpCards !== undefined) {
        this.game.tmpCards = gameData.tmpCards
      }

      if (gameData.winners !== undefined) {
        this.game.winners = gameData.winners
      }

      if (gameData.guesed !== undefined) {
        this.game.guesed = gameData.guesed
      }

      if (gameData.answer !== undefined) {
        this.game.answer = gameData.answer
      }

      if (gameData.animation !== undefined) {
        this.game.animation = gameData.animation
      }

      if (gameData.logs !== undefined) {
        this.game.logs = gameData.logs
      }

      if (gameData.random !== undefined) {
        this.game.random = gameData.random
      }
      
      if (gameData.players) {
        const oldPlayers = this.game.players.map(p => p)
        this.game.players = gameData.players.map(player => {
          const playerData = {
            ...player,
            status: player.status,
            isSuperTurn: player.isSuperTurn,
            cardsCount: player.cardsCount,
          }
          
          const hasPlayer = oldPlayers.find(p => p.id === player.id)

          if (player.cards && player.cards.length) {
            playerData.cards = player.cards.map(card => new Card(card))
          }
          else {
            if (hasPlayer) {
              playerData.cards = hasPlayer.cards.map(c => c)
            }
          }

          if (hasPlayer) {
            return new GamePlayer({
              ...playerData,
              status: player.status,
              isSuperTurn: player.isSuperTurn,
            })
          }
          else return new GamePlayer(playerData)
        })
      }

      if (gameData.mode === GameModes.CRASHED) {
        this.game.players = []
      }
    }
  }

  @Mutation
  switchHiglightWord({ id, value }: { id: number, value: boolean }) {
    this.room?.players.forEach(player => {
      const card = player.cards.find(card => card.words.some(word => word.id === id))
      if (card) {
        const word = card.words.find(word => word.id === id)
        if (word) {
          console.log(word.active, value)
          word.active = value
        }
      }
    })
  }

  @Mutation
  resetAll() {
    this.room = null
    this.game = null
  }

  @Mutation
  resetGame() {
    this.game = null
  }

  @Mutation
  resetRoom() {
    this.room = null
  }
}

export { SystemModule }