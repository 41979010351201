enum SubjectTypes {
  PLAYER = 'player',
  SPECTATOR = 'spectator'
}

abstract class Subject {
  id: string
  abstract type: SubjectTypes

  constructor(id: string) {
    this.id = id
  }
}

export { Subject, SubjectTypes }