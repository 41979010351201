
import { Component } from 'vue-property-decorator';
import { appModule, userModule } from './store';

import Logo from '@/components/UI/Logo.vue';
import LoaderUI from '@/components/UI/Loader.vue'
import ModalUI from '@/components/UI/Modal.vue'
import TextUI from '@/components/UI/Text.vue'
import { socketClient } from './modules/SocketClient';
import { mixins } from 'vue-class-component';
import SocketMixin from './components/mixins/SocketMixin';

import { AppService } from '@/services/AppService'

@Component({
  components: {
    Logo,
    LoaderUI,
    ModalUI,
    TextUI
  }
})
export default class App extends mixins(SocketMixin) {
  loaded = false
  cacheWidth = 0
  cacheHeight = 0

  hasError = false

  showExitModal(e: Event) {
    if(this.$route.name === 'game') {
      this.$root.$emit('game:exitModal')
      e.preventDefault()
    }
  }

  get isConnect() {
    return socketClient.connected
  }

  get gameCrashed() {
    return appModule.gameCrashed
  }

  switchLang() {
    const locale = this.$i18n.locale === 'ru' ? 'en' : 'ru'
    userModule.updateLang(locale)
    this.$i18n.locale = locale
  }

  created() {
    const appService = new AppService
    userModule.loadUser()

    Promise.all(
      Array
        .from(document.images)
        .filter(img => !img.complete)
        .map(img => 
          new Promise(resolve => { img.onload = img.onerror = resolve; })
        )
    )
    .then(async () => {
      const res = await appService.getUpdateInfo()
      if(res) {
        appModule.updateInfo(res.data)
      }
    })
    .then(() => {
      console.log('loaded')
      this.loaded = true
    })
    .catch(() => {
      this.loaded = true
    })

    const name = window.localStorage.getItem("user_name")
    if(name) {
      userModule.setName(name)
    }
    const lang = window.localStorage.getItem('lang')
    if (lang) {
      userModule.updateLang(lang)
      this.$i18n.locale = lang
    }

    this.cacheWidth = window.innerWidth
    this.cacheHeight = window.innerHeight

    window.addEventListener("resize", () => {
      if(window.innerHeight === this.cacheHeight) return

      this.cacheHeight = window.innerHeight
    })

    this.initSocket()

    setInterval(() => {
      this.hasError = !socketClient.hasConnection
    }, 3000)

    this.$root.$on('game:crashed', () => {
      const clickListener = () => {
        appModule.updateGameCrashed(false)
        document.removeEventListener('click', clickListener)
      }
      appModule.updateGameCrashed(true)
      document.addEventListener('click', clickListener)
    })
  }
}
