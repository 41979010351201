import { Card } from "./Card"
import { Room } from "./Room"
import { Word } from '@/store/entities/Card';
import { GamePlayer } from "./Player";

enum GameModes {
  PAUSED = 0,
  WAITING = 1,
  TURN = 2,
  FINISHED = 3,
  PREPARING = 4,
  FINISHED_RESTART = 5,
  CRASHED = 99,
  EMPTY = 13
}

interface Status {
  id: string
  statusKey: string
  messageKey: string
  status: {
    ru: string
    en: string
  }
  message: {
    ru: string
    en: string
  }
  highlight: boolean
}

enum GameDifficulty {
  EASY = 'easy',
  HARD = 'hard'
}

enum GameStatuses {
  DEFAULT = 1,
  HINT = 54,
  GUESS = 55,
  NO_ONE_GUES = 56
}
interface Gues {
  id: string,
  count: number
  words: Word[]
  timestamp: number
}

enum AnimationType {
  FAIL = 'fail',
  SUCESS = 'success'
}

interface Log {
  playerId: string,
  date: Date,
  text: string
}

interface Winner {
  id: string,
  priority?: boolean
}

type Winners = Winner[]

class GameDTO {
  id: string
  sessionId: string
  hint?: string
  currentPlayerId?: string
  nextPlayerId?: string
  players?: (GamePlayer & { cards?: Card[] })[]
  timer: number
  tmpCards?: Card[] | null = null
  mode?: GameModes
  round: number
  status?: GameStatuses
  extra?: {
    isSuperTurn: boolean,
    freezeStatus: boolean,
    superTurnBy: string,
    paused: boolean
  }
  isSuperTurn?: boolean
  winners?: Winners = []
  difficulty?: GameDifficulty
  guesed?: Gues[] = []
  answer?: Word[]
  animation?: {
    type?: AnimationType,
    color?: string
    words: Word[]
    text: string
  }
  logs?: string[]
  statuses?: Status[]
  random: number

  constructor({
    id,
    sessionId,
    hint,
    currentPlayerId,
    nextPlayerId,
    timer,
    players,
    mode,
    round,
    status,
    tmpCards,
    winners,
    extra,
    isSuperTurn,
    difficulty,
    guesed,
    answer,
    animation,
    logs,
    statuses,
    random
  }: {
      id: string,
      sessionId: string
      hint?: string,
      currentPlayerId?: string,
      nextPlayerId?: string,
      timer: number,
      players?: (GamePlayer & { cards?: Card[] })[]
      mode?: GameModes,
      round: number,
      status?: GameStatuses
      tmpCards?: Card[],
      winners?: Winners,
      extra?: {
        isSuperTurn: boolean,
        freezeStatus: boolean,
        superTurnBy: string,
        paused: boolean
      },
      isSuperTurn?: boolean,
      difficulty?: GameDifficulty,
      guesed?: Gues[],
      answer?: Word[]
      animation?: {
        type?: AnimationType,
        color?: string
        words: Word[]
        text: string
      }
      logs?: string[],
      statuses?: Status[]
      random: number
  }) {
    this.id = id
    this.sessionId = sessionId
    this.timer = timer
    this.hint = hint
    this.currentPlayerId = currentPlayerId
    this.nextPlayerId = nextPlayerId
    this.players = players
    this.mode = mode
    this.round = round
    this.tmpCards = tmpCards
    this.winners = winners
    this.extra = extra
    this.isSuperTurn = isSuperTurn
    this.difficulty = difficulty
    this.guesed = guesed
    this.answer = answer
    this.animation = animation
    this.logs = logs
    this.statuses = statuses
    this.random = random
    this.status = status
  }
}

class Game {
  id: string
  sessionId = ''
  hint = ''
  guesWords: string[] = []
  words: string[] = []
  currentPlayerId = ''
  nextPlayerId = ''
  room: Room
  timer: number
  tmpCards?: Card[] | null = null
  players: GamePlayer[] = []
  mode: GameModes = GameModes.PREPARING
  round = 0
  status: GameStatuses = GameStatuses.DEFAULT
  difficulty: GameDifficulty = GameDifficulty.EASY
  winners?: Winners = []
  extra: {
    isSuperTurn: boolean,
    freezeStatus: boolean,
    superTurnBy: string,
    paused: boolean
  } = {
    isSuperTurn: false,
    freezeStatus: false,
    superTurnBy: '',
    paused: false
  }
  isSuperTurn = false
  guesed: Gues[] = []
  answer?: Word[]
  animation: {
    type?: AnimationType | undefined,
    color?: string
    words: Word[]
  } = {
      type: undefined,
      color: undefined,
      words: []
    }
  logs: string[] = []
  statuses: Status[] = []
  random = 0

  constructor(gameId: string, room: Room) {
    this.room = room
    this.id = gameId
    this.timer = room.timer
  }

  resetAnimationColor() {
    this.animation.color = ''
  }

  resetAnimation() {
    this.animation = {
      color: '',
      type: undefined,
      words: []
    }
  }
}

export { Game, GameDTO, GameModes, GameStatuses, GameDifficulty, Gues, AnimationType, Log, Status, Winner, Winners }