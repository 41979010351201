
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class ButtonUI extends Vue {
  @Prop({ type: Boolean }) disabled!: boolean
  @Prop({ type: String }) text!: string
  @Prop({ type: [String, Object] }) href!: string
  @Prop({ type: String }) size!: string
}
