import { GameDifficulty, GameModes, Gues, Status, GameStatuses } from "./Game"
import { Card } from "./Card"
import { SubjectTypes } from "./Subject"
import { Winners } from '@/store/entities/Game'
import { PlayerStatuses, PlayerStages } from "./Player";
import { RoomModes, Winner, RoomData as IRoomData } from '@/store/entities/Room';
import { Word } from '@/store/entities/Card'; 

enum GameMessageType {
  HOOK = 'hook',
  DATA = 'data',
  FULL = 'full'
}

type Command = 'connect' | 'action' | 'create' | 'room' | 'game' | 'client' | 'start' | 'update' | 'upgrade' | 'leave' | 'info' | 'room-info' | 'turn' | 'restart' | 'continue' | 'new-turn' | 'verify' | 'verify-session' | 'public' | 'public-hook' | 'notify' | 'players-activity' | 'awake'
type ActionTypes = 'hint' | 'gues-words' | 'answer-words' | 'info' | 'super-turn' | 'animate' | 'animate-off' | 'animate-end' | 'flip'

interface BaseMetaData {
  _v?: number
}
interface BaseData {
  _v?: number
}

interface UpgradeMetaData extends BaseMetaData {
  roomId: string
  id: string
  user: {
    id: string,
  }
  type: 'add' | 'delete'
}

interface DataMetaData extends BaseMetaData {
  type: GameMessageType
}

interface LeaveMetaData extends BaseMetaData {
  roomId: string
  id: string
}

interface ClientMetaData extends BaseMetaData {
  id: string
  roomId: string
}

interface TurnMetaData extends BaseMetaData {
  type: 'hook' | 'data' | 'full' 
}

interface PublicHookMetaData extends BaseMetaData {}

interface NewTurnMetaData extends BaseMetaData {
  gameId: string
  roomId: string
  socketId: string
  user: {
    id: string
    name: string
    color: string
    ready: boolean
    type: 'player' | 'spectator'
  }
}

interface CreateMetaData extends BaseMetaData {
  roomId: string
  ownerId: string
  timer: number
  difficulty: GameDifficulty
  cardsCount: number
  isPublic?: boolean
}

interface GameMetaData extends BaseMetaData {
  gameId: string
  roomId: string
  type: 'hook' | 'data' | 'full' | 'animation'
}

interface InfoMetaData extends BaseMetaData {
  gameId: string
  roomId: string
  type?: 'hook' | 'data' | 'full'
  socketId: string
  user: {
    id: string
    name: string
    color: string
    ready: boolean
    type: 'player' | 'spectator'
  }
}

interface RoomInfoMetaData extends BaseMetaData {
  gameId: string
  roomId: string
  force?: boolean
  socketId: string
  user: {
    id: string
    name: string
    color: string
    ready: boolean
    type: 'player' | 'spectator'
  }
}

interface PublicMetaData extends BaseMetaData {}

interface VerifyDataMetadata extends BaseMetaData {
  gameId: string
  roomId: string
  socketId: string
  rules: ("public")[]
  user: {
    id: string
    name: string
    color: string
    ready: boolean
    type: SubjectTypes
  }
}

interface VerifySessionMetadata extends BaseMetaData {
  gameId: string
  roomId: string
  socketId: string
  hasRoom?: boolean
  hasGame?: boolean
}

interface RestartMetaData extends BaseData {
  roomId: string
  socketId: string
  id: string
}

interface RoomMetaData extends BaseMetaData {
  id: string
  ownerId: string
  timer: number
  cardsCount: number
  difficulty: GameDifficulty
  mode: RoomModes
  isPlaying: boolean
  blocked: string[]
}

interface ConnectMetaData extends BaseMetaData {
  roomId: string
  socketId: string
  user: {
    id: string
    name: string
    color: string
    ready: boolean
    type: 'player' | 'spectator'
  }
  isNewGame?: boolean
}

interface ActionMetaData extends BaseMetaData {
  roomId: string
  gameId: string
  socketId: string
  user: {
    id: string
    name: string
    color: string
    ready: boolean
    type: 'player' | 'spectator'
  }
  type: ActionTypes
}

interface StartMetaData extends ConnectMetaData {
  gameId: string
  roomId: string
  difficulty: GameDifficulty
}

interface ContinueMetaData extends BaseMetaData {
  roomId: string
  socketId: string
  gameId: string
  user: {
    id: string,
    color: string,
    name: string,
    ready: boolean
    type: SubjectTypes
  }
}

interface UpgradeData extends BaseData {}
interface LeaveData extends BaseData {}
interface ClientData extends BaseData {}
interface TurnData extends BaseData {}
interface PublicHookData extends BaseData {}
interface NewTurnData extends BaseData {}
interface ConnectData extends BaseData {}
interface InfoData extends BaseData { }
interface RoomInfoData extends BaseData { }
interface VerifyDataData extends BaseData { }
interface VerifySessionData extends BaseData {}
interface RestartData extends BaseData { }
interface ContinueData extends BaseData { }
interface ActionData extends BaseData {
  [key: string]: any
}
interface CreateData extends BaseData {}
interface RoomData extends BaseData {
  users: {
    id: string
    name: string
    color: string
    ready: boolean
    type: SubjectTypes
  }[]
  winners: Winner[]
}

interface PublicData extends BaseData {
  rooms: IRoomData[]
}

interface GameData extends BaseData {
  players: {
    id: string,
    cards?: Card[],
    cardsCount: number
    status: PlayerStatuses
    isSuperTurn: boolean
    ready: boolean
    name: string
    stage: PlayerStages
  }[]
  mode?: GameModes
  round: number
  status?: GameStatuses
  sessionId: string
  tmpCards?: Card[]
  guesed?: Gues[]
  answer?: Word[]
  extra?: {
    isSuperTurn: boolean,
    freezeStatus: boolean,
    superTurnBy: string,
    paused: boolean
  }
  isSuperTurn?: boolean
  currentPlayerId?: string
  nextPlayerId?: string
  hint?: string
  timer: number
  difficulty?: GameDifficulty
  winners?: Winners
  logs?: string[]
  statuses?: Status[]
  random: number
}

interface StartData extends BaseData {}

type CommandMap<T = any> = {[K in Command]: T}

interface MetaData extends CommandMap<BaseMetaData> {
  client: ClientMetaData,
  connect: ConnectMetaData,
  create: CreateMetaData,
  room: RoomMetaData,
  game: GameMetaData,
  action: ActionMetaData,
  start: StartMetaData,
  upgrade: UpgradeMetaData,
  leave: LeaveMetaData,
  info: InfoMetaData,
  'room-info': RoomInfoMetaData,
  turn: DataMetaData,
  restart: RestartMetaData,
  continue: ContinueMetaData,
  verify: VerifyDataMetadata,
  'verify-session': VerifySessionMetadata,
  public: PublicMetaData,
  'public-hook': PublicHookMetaData,
  notify: ClientMetaData,
  'players-activity': { roomId: string } & BaseMetaData
}

interface ContentData extends CommandMap<BaseData> {
  client: ClientData,
  connect: ConnectData,
  create: CreateData,
  room: RoomData,
  game: GameData,
  action: ActionData,
  start: StartData,
  upgrade: UpgradeData,
  leave: LeaveData,
  info: InfoData,
  'room-info': RoomInfoData,
  turn: TurnData,
  restart: RestartData,
  continue: ContinueData,
  verify: VerifyDataData,
  'verify-session': VerifySessionData,
  public: PublicData,
  'public-hook': PublicHookData,
  notify: ClientData,
  'players-activity': { players: { id: string, sleep: number }[] } & BaseData
}

type Data = {
  [K in keyof CommandMap]: {
    meta: MetaData[K],
    content?: ContentData[K]
  }
}

interface MessageData<K extends Command = any, D extends Data[K] = Data[K]> {
  meta: D['meta']
  content?: D['content']
}

class Message<K extends Command = any, M extends MessageData<K> = MessageData<K>> {
  meta: M['meta']
  content: M['content']

  constructor(jsonData: M) {
    this.meta = jsonData.meta
    this.content = jsonData.content
  }
}

export { Message, ConnectMetaData, ActionMetaData, CreateMetaData, Command, ActionTypes }