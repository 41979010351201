import { Command, Message } from '@/store/entities/Message';
import { Events, EventSystem, UNIVERSAL_TYPE } from "./EventSystem"
import { v4 as uuid } from 'uuid';
import { SocketClient } from '@/modules/SocketClient';

export type CustomEvents = {
  [K in Command]: (msg: Message<K>) => void
} & {
  connected: (msg: { id: string }) => void
  reconnect: () => void
  close: () => void
  forceClose: () => void
  ping: () => void
  pong: () => void
}

enum MessageStatus {
  COMPLETE = 0,
  PENDING = 1,
  WAITING = 2
}

interface MessageRecord {
  messageId: string,
  status: MessageStatus
  event: string,
  eventType: number
  retry: number
  timestamp: number
  createDate: number,
  remove?: boolean
  messageHandle: (socket: WebSocket) => void
}

class WebSocketClient<E extends Events> extends EventSystem<E> {
  private url: string
  id: string
  stack: MessageRecord[] = []
  hooksStack: MessageRecord[] = []
  master: SocketClient<E>
  private socket!: WebSocket

  stackInterval: any
  reconnectInterval: any
  verifyConnectInterval: any
  heartBeatInterval: any
  heartBeatIntervalBreak: any
  heartBeatRequestInterval: any
  heartBeatInt: any
  idleInterval: any
  logInterval: any
  awaiting = false

  leave = true
  lastAction: number = Date.now()
  lastPing: number = Date.now()

  constructor(url: string, id: string, master: SocketClient<E>) {
    super()

    this.master = master

    this.url = url
    this.id = id

    this.logInterval = setInterval(() => {
      // console.log(this.stack.map(s => s))
    }, 1000)

    this.idleInterval = setInterval(() => {
      if (Date.now() - this.lastPing > 4000) {
        if (this.socket.readyState === WebSocket.OPEN) {
          this.socket.close()
        }
      }
    }, 500)

    this.stackInterval = setInterval(() => {
      if (!this.socket) return
      if (this.socket.readyState !== WebSocket.OPEN) return

      const { stack } = this

      const messageRecord = stack[0]

      if (messageRecord) {
        if (messageRecord.status === MessageStatus.WAITING) {
          messageRecord.messageHandle(this.socket)
          messageRecord.status = MessageStatus.PENDING
        }

        if (messageRecord.status === MessageStatus.PENDING) {
          if (Date.now() - messageRecord.timestamp > 500) {
            messageRecord.status = MessageStatus.WAITING
            messageRecord.timestamp = Date.now()
            messageRecord.retry++
            console.log('retry', messageRecord.retry, messageRecord.eventType, messageRecord.messageId)
            if (messageRecord.retry > 5) {
              stack.splice(0, 1, {
                ...messageRecord,
                messageId: uuid()
              })
            }
            if (messageRecord.retry > 15) {
              this.socket.close()
            }
          }
        }
  
        if (messageRecord.status === MessageStatus.COMPLETE) {
          if (messageRecord.retry > 0) {
            console.log(this.stack.length)
            // console.log(this.stack.map(s => s))
          }
          stack.splice(0, 1)
        }

        if (stack.length > 50) {
          this.socket.close()
        }
      }
    }, 10)
  }

  registerAction() {
    this.lastAction = Date.now()
    this.master.registerAction()
  }

  registerPing() {
    this.lastPing = Date.now()
    this.master.registerPing()
  }
  
  getSocket() {
    return this.socket
  }
  
  init() {
    this.socket = new WebSocket(`${this.url}`)
    this.initSocket(0)
  }

  registerMessageComplete(messageId: string) {
    const stackMessage = this.stack.find(m => m.messageId === messageId)
    if (stackMessage) {
      stackMessage.status = MessageStatus.COMPLETE
    }
  }

  addToStack(msg: MessageRecord) {
    if (msg.eventType === UNIVERSAL_TYPE) return this.stack.push(msg)
    this.stack.forEach(message => {
      if (message.status === MessageStatus.WAITING && message.eventType === msg.eventType) {
        message.remove = true
      }
    })
    this.stack = this.stack.filter(m => !m.remove)
    this.stack.push(msg)
  }

  reconnect(err: CloseEvent) {
    console.error('socket closed')

    if (err.code === 1006 || err.code === 1005) {
      console.warn('disconnect from client')
      this.reconnectInterval = setTimeout(() => {
        if (this.socket.readyState === WebSocket.CLOSED) {
          this.master.close()
        }
      }, 1000)
    }
    else {
      console.warn('disconnect from server')
      this.reconnectInterval = setTimeout(() => {
        if (this.socket.readyState === WebSocket.CLOSED) {
          this.master.close()
        }
      }, 3000)
    }
  }

  closeHandler(err: CloseEvent) {
    this.reconnect(err)
  }

  initSocket(count: number) {
    if (count >= 10) window.location.reload()
    
    this.stack.forEach(msg => {
      if (msg.status === MessageStatus.PENDING) {
        msg.status = MessageStatus.WAITING
      }
    })

    this.socket.addEventListener('error', (err: Event) => {
      this.socket.close()
    })

    this.socket.addEventListener('close', this.closeHandler.bind(this))

    this.socket.addEventListener('open', () => {
      this.master.clearReconnectInterval()
      count = 0

      this.heartBeatInt = setInterval(() => {
        this.heartBeat()
      }, 2000)

      this.socket.addEventListener('message', msg => {
        const msgData = JSON.parse(msg.data.toString())
        const { event, data, messageId } = msgData

        if (event === 'connected' || event === 'reconnect') {
          clearTimeout(this.heartBeatInterval)
          clearTimeout(this.heartBeatIntervalBreak)
        }

        if (event === 'complete') {
          this.registerMessageComplete(messageId)
        }

        if (event === 'heartbeat') {
          this.registerPing()
        }
        
        if (event !== 'heartbeat' && event !== 'complete') {
          this.socket.send(JSON.stringify({
            event: 'complete',
            messageId: messageId,
            data: {}
          }))

          this.trigger(event, data)
        }
      })
    })
  }

  heartBeat() {
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify({
        event: 'heartbeat',
        messageId: uuid(),
        data: {}
      }))
    }
  }

  clear() {
    if (this.socket) {
      this.socket.onclose = null
      this.socket.onmessage = null
      this.socket.onerror = null
      this.socket.onopen = null
    }
    clearInterval(this.logInterval)
    clearInterval(this.idleInterval)
    clearInterval(this.heartBeatInt)
    clearInterval(this.stackInterval)
    clearInterval(this.reconnectInterval)
    clearInterval(this.verifyConnectInterval)
    clearInterval(this.heartBeatInterval)
    clearInterval(this.heartBeatIntervalBreak)
    clearInterval(this.heartBeatRequestInterval)
    this.kill()
    this.clearEvents()
  }

  clearEvents() {
    this.events.clear()
  }

  kill() {
    if (!this.socket) return
    try {
      if (this.socket.readyState === WebSocket.OPEN) {
        this.socket.close()
      }
      else {
        setTimeout(() => {
          this.kill()
        }, 1000)
      }
    }
    catch (e) {}
  }

  off<K extends keyof E>(keys: K[]) {
    keys.forEach(key => {
      this.events.delete(key)
    })
  }

  emitter<K extends keyof E>(key: K, data: Parameters<E[K]>[], type = UNIVERSAL_TYPE): void {
    const id = uuid()

    if (key === 'heartbeat') return
    if (key === 'complete') return
    if (key === 'close') return
    if (key === 'error') return
    if (key === 'forceClose') return

    const msg = {
      messageId: id,
      event: String(key),
      eventType: type,
      status: MessageStatus.WAITING,
      timestamp: Date.now(),
      createDate: Date.now(),
      retry: 0,
      messageHandle: (socket: WebSocket) => {
        socket.send(JSON.stringify({
          event: key,
          messageId: id,
          data
        }))
      }
    }

    this.addToStack(msg)
    this.registerAction()
  }
}

export { WebSocketClient }