import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";
import { v4 as uuid } from 'uuid'
import { SubjectTypes } from "../entities/Subject";

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'UserModule'
})
class UserModule extends VuexModule {
  id = ''
  name = ''
  color = 'red'
  type: SubjectTypes = SubjectTypes.PLAYER
  ready = false
  lang = 'ru'

  LOADED = false

  get data() {
    return {
      id: this.id,
      name: this.name,
      color: this.color,
      type: this.type,
      ready: this.ready,
      lang: this.lang
    }
  }

  @Mutation
  setLoaded() {
    this.LOADED = true
  }

  @Mutation
  setName(name: string) {
    this.name = name
  }

  @Mutation
  setColor(color: string) {
    this.color = color
  }

  @Mutation
  setId(id: string) {
    this.id = id
  }

  @Mutation
  setReady(ready: boolean) {
    this.ready = ready
  }

  @Mutation
  setLang(lang: string) {
    this.lang = lang
  }

  @Action
  updateLang(lang: string) {
    this.context.commit('setLang', lang)
    localStorage.setItem('lang', lang)
  }

  @Action
  loadUser() {
    const hasId = window.localStorage.getItem('id')
    const hasLang = window.localStorage.getItem('lang')

    if (!hasLang) {
      window.localStorage.setItem('lang', 'ru')
    }

    if (!hasId) {
      window.localStorage.setItem('id', uuid())
    }

    const id = window.localStorage.getItem('id')
    const lang = window.localStorage.getItem('lang')

    this.context.commit('setId', id)
    this.context.commit('setLoaded')
    this.context.commit('setLang', lang)
  }
}

export { UserModule }