
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import ButtonUI from './Button.vue';
import TextUI from './Text.vue';

@Component({
  components: {
    ButtonUI,
    TextUI
  }
})
export default class ModalUI extends Vue {
  @Prop({ type: Boolean }) visible!: boolean
  @Prop({ type: Function }) resolve!: <T>(event: T) => void 
  @Prop({ type: Function }) reject!: <T>(event: T) => void 
}
