import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { systemModule, userModule } from '@/store';
import { Message } from '@/store/entities/Message';
import { RoomDTO } from '@/store/entities/Room';
import { UserDTO } from '@/store/entities/User';
import { GameDTO } from '@/store/entities/Game';
import { SubjectTypes } from '@/store/entities/Subject';
import { socketClient, MessageType } from '@/modules/SocketClient';
import { GamePlayer } from '@/store/entities/Player';

@Component
export default class SocketMixin extends Vue {
  t: any
  tt: any

  get room() {
    return systemModule.room
  }

  get game() {
    return systemModule.game
  }

  emitInfo(type?: 'hook' | 'data' | 'full') {
    socketClient.emit('info', new Message<'info'>({
      meta: {
        user: new UserDTO({
          id: userModule.id,
          name: userModule.name,
          color: userModule.color,
          type: SubjectTypes.PLAYER,
          ready: userModule.ready
        }),
        type,
        roomId: this.room?.id || '',
        gameId: this.game?.id || '',
        socketId: socketClient.id
      },
    }), type === 'full' ? MessageType.FULL_INFO_MESSAGE : MessageType.DATA_INFO_MESSAGE)
  }

  emitRoomInfo(force?: boolean) {
    socketClient.emit('room-info', new Message<'room-info'>({
      meta: {
        user: new UserDTO({
          id: userModule.id,
          name: userModule.name,
          color: userModule.color,
          type: SubjectTypes.PLAYER,
          ready: userModule.ready,
        }),
        force,
        roomId: this.room?.id || '',
        gameId: this.game?.id || '',
        socketId: socketClient.id
      },
    }), MessageType.ROOM_INFO_MESSAGE)
  }

  emitVerify() {
    const routes = ['game', 'room', 'rooms', 'index', 'rules']
    const rules: ('public')[] = []

    if (this.$route.name === 'rooms' || this.$route.name === 'index') {
      rules.push('public')
    }
    
    if (routes.includes(this.$route.name || '')) {
      socketClient.emit('verify', new Message<'verify'>({
        meta: {
          user: new UserDTO({
            id: userModule.id,
            name: userModule.name,
            color: userModule.color,
            type: SubjectTypes.PLAYER,
            ready: userModule.ready,
          }),
          roomId: this.room?.id || '',
          gameId: this.game?.id || '',
          socketId: socketClient.id,
          rules
        },
      }), MessageType.VERIFY_DATA_MESSGE)
    }
  }

  emitVerifySession() {
    socketClient.emit('verify-session', new Message<'verify-session'>({
      meta: {
        roomId: this.room?.id || '',
        gameId: this.game?.id || '',
        socketId: socketClient.id
      }
    }))
  }

  initSocket() {
    console.warn('init socket client...')
    
    socketClient.on('connected', (msg: { id: string }) => {
      const { id } = msg
      console.warn('socket connected, init events to client...')
      
      socketClient.updateId(id)
      socketClient.connecting = false
      socketClient.hasConnection = true

      socketClient.emit('upgrade', new Message({
        meta: {
          id: socketClient.id,
          roomId: this.room?.id || '',
          user: {
            id: userModule.id,
          },
          type: 'add'
        },
        content: {}
      }), MessageType.UPGRADE_MESSAGE)

      this.emitVerify()
      // this.emitVerifySession()
    })

    socketClient.on('room', msg => {
      const { meta, content } = msg

      systemModule.updateRoom({
        roomData: new RoomDTO({
          ...meta,
          players: content ? content.users.map(u => new UserDTO(u)) : [],
          winners: content ? content.winners : []
        })
      })
    })

    socketClient.on('public', msg => {
      const { content } = msg

      if (content) {
         systemModule.setPublicRooms(content.rooms)
       }
    })

    socketClient.on('game', msg => {
      const { meta, content } = msg

      if (!systemModule.game) return

      if (content) {
        if (meta.type === 'hook') {
          systemModule.updateGame({
            gameData: new GameDTO({
              id: meta.gameId,
              sessionId: content.sessionId,
              timer: content.timer,
              round: content.round,
              random: content.random
            })
          })
        }

        if (meta.type === 'data') {
          systemModule.updateGame({
            gameData: new GameDTO({
              id: meta.gameId,
              sessionId: content.sessionId,
              hint: content.hint,
              timer: content.timer,
              currentPlayerId: content.currentPlayerId,
              nextPlayerId: content.nextPlayerId,
              mode: content.mode,
              round: content.round,
              status: content.status,
              difficulty: content.difficulty,
              extra: content.extra,
              isSuperTurn: content.isSuperTurn,
              guesed: content.guesed,
              answer: content.answer,
              winners: content.winners,
              logs: content.logs,
              statuses: content.statuses,
              players: content.players.map(player => new GamePlayer({
                id: player.id,
                status: player.status,
                isSuperTurn: player.isSuperTurn,
                cardsCount: player.cardsCount,
                ready: player.ready,
                name: player.name,
                stage: player.stage
              })),
              random: content.random
            })
          })
        }

        if (meta.type === 'full') {
          systemModule.updateGame({
            gameData: new GameDTO({
              id: meta.gameId,
              sessionId: content.sessionId,
              hint: content.hint,
              timer: content.timer,
              currentPlayerId: content.currentPlayerId,
              nextPlayerId: content.nextPlayerId,
              mode: content.mode,
              round: content.round,
              status: content.status,
              difficulty: content.difficulty,
              extra: content.extra,
              isSuperTurn: content.isSuperTurn,
              guesed: content.guesed,
              answer: content.answer,
              tmpCards: content.tmpCards,
              winners: content.winners,
              logs: content.logs,
              statuses: content.statuses,
              players: content.players.map(player => new GamePlayer({
                id: player.id,
                status: player.status,
                isSuperTurn: player.isSuperTurn,
                cardsCount: player.cardsCount,
                cards: player.cards,
                ready: player.ready,
                name: player.name,
                stage: player.stage
              })),
              random: content.random
            })
          })
        }
      }
    })

    socketClient.on('start', msg => {
      const { meta } = msg
    })

    socketClient.on('players-activity', msg => {
      const { content } = msg

      if (content) {
        systemModule.updateRoomPlayersSleep(content.players)
      }
    })

    socketClient.on('leave', msg => {
      // const { meta } = msg
      // console.log(11)
      // const playerIndex = this.game?.players.findIndex(p => p.id === meta.id)
      // if (playerIndex && playerIndex !== -1) {
      //   this.game?.players.splice(playerIndex, 1)
      // }
    })

    socketClient.on('restart', () => {
      const { room } = this

      if (room) {
        this.$router.push(`/${room.id}`)
      }
    })

    socketClient.on('verify-session', msg => {
      const { meta } = msg
      if(this.$route.name === 'game' && !meta.hasGame) return location.reload()
      if(this.$route.name === 'room' && !meta.hasRoom) return location.reload()
    })

    socketClient.on('info', () => {
      this.emitVerify()
    })

    socketClient.on('room-info', () => {
      this.emitVerify()
    })

    socketClient.on('turn', msg => {
      const { meta } = msg

      this.emitVerify()
    })

    socketClient.on('public-hook', msg => {
      this.emitVerify()
    })

    socketClient.killSocket()
    socketClient.init()
    socketClient.start()

    socketClient.updateConnect(true)
    this.verifyRequest()
    this.awakeLoop()
  }

  verifyRequest() {
    clearInterval(this.t)
    this.t = setInterval(() => {
      const socket = socketClient.socket?.getSocket()
      if (socket) {
        if (socket.readyState === WebSocket.OPEN) {
          if(Date.now() - socketClient.lastAction > 3000)
            this.emitVerify()
        }
      }
    }, 500)
  }

  awakeLoop() {
    clearInterval(this.tt)
    // this.tt = setInterval(() => {
    //   if (this.room) {
    //     socketClient.emit('awake', new Message<'client'>({
    //       meta: {
    //         id: userModule.id,
    //         roomId: this.room.id,
    //       }
    //     }), MessageType.AWAKE_MESSAGE)
    //   }
    // }, 1000)
  }

  updateSocket() {
    socketClient.updateSocket()
  }

  beforeDestroy() {
    clearInterval(this.t)
    clearInterval(this.tt)
  }
}