interface Word {
  id: number
  ru: string,
  eng: string
  active: boolean
}

class Card {
  id: string
  words: Word[] = []
  indexes: [number, number, number]
  
  constructor(cardData: { id: string, words: Omit<Word, 'active'>[], indexes: [number, number, number] }) {
    this.id = cardData.id
    this.indexes = cardData.indexes
    this.words = cardData.words.map(word => ({ ...word, active: false }))
  }
}

export { Card, Word }