import Vue from "vue";
import Vuex from "vuex";

import { UserModule } from './modules/User'
import { SystemModule } from './modules/System'
import { TranslateModule } from './modules/Translate'
import { getModule } from 'vuex-module-decorators';
import { AppModule } from "./modules/App";
// import { SocketModule } from "./modules/Socket";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    translate: {}
  },
  getters: {},
  mutations: {
    setTranslate(state, data) {
      state.translate = data
    }
  },
  actions: {},
  modules: {
    SystemModule,
    UserModule,
    TranslateModule,
    AppModule
    // SocketModule
  },
});

const userModule = getModule(UserModule, store)
const systemModule = getModule(SystemModule, store)
const translateModule = getModule(TranslateModule, store)
const appModule = getModule(AppModule, store)
// const socketModule = getModule(SocketModule, store)

export {
  userModule,
  systemModule,
  translateModule,
  appModule
  // SocketModule
}

export default store