
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class TextUI extends Vue {
  @Prop({ type: String }) text!: string
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  @Prop({ type: Boolean, default: false }) pointer!: boolean
  @Prop({ type: Boolean, default: false }) inline!: boolean
  @Prop({ type: String }) size!: 'small' | 'big'
  @Prop({ type: String }) color!: 'primary' | 'white'
}
