import { Card } from "./Card";
import { Subject, SubjectTypes } from "./Subject";


enum PlayerStatuses {
  FREEZED = 0,
  ACTIVE = 1,
  FINISHED = 2,
  NULL = 3
}

enum PlayerStages {
  STATIC = 1,
  CHANGE_CARD = 4
}

class Player extends Subject {
  name: string
  color: string
  type = SubjectTypes.PLAYER
  cards: Card[] = []
  cardsCount = 0
  score = 0
  ready = false
  status: PlayerStatuses = PlayerStatuses.NULL
  sleep = 0
  
  constructor(user: { id: string, name: string, color: string, cards?: Card[], status?: PlayerStatuses, ready: boolean, sleep: number }) {
    super(user.id)

    this.name = user.name
    this.sleep = user.sleep
    this.color = user.color
    this.ready = user.ready

    if (user.cards) {
      this.cards = user.cards
    }
    if (user.status !== undefined) {
      this.status = user.status
    }
  }
}

class GamePlayer {
  id: string
  isSuperTurn: boolean
  cards: Card[] = []
  cardsCount: number
  status: PlayerStatuses
  ready: boolean
  name: string
  stage: PlayerStages = PlayerStages.STATIC

  constructor(player: { id: string, cards?: Card[], cardsCount: number, status: PlayerStatuses, isSuperTurn: boolean, name: string, ready: boolean, stage: PlayerStages }) {
    this.id = player.id
    if (player.cards) {
      this.cards = player.cards
    }
    this.cardsCount = player.cardsCount
    this.status = player.status
    this.isSuperTurn = player.isSuperTurn
    this.name = player.name
    this.ready = player.ready
    this.stage = player.stage
  }
}

export { Player, PlayerStages, PlayerStatuses, GamePlayer }