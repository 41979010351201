import { Card } from "./Card"
import { PlayerStatuses } from "./Player"
import { SubjectTypes } from "./Subject"

class UserDTO {
  id: string
  name: string
  type: SubjectTypes
  color: string
  ready: boolean
  cards?: Card[]
  status?: PlayerStatuses
  sleep: number

  constructor(user: { id: string, name: string, type: SubjectTypes, color: string, cards?: Card[], status?: PlayerStatuses, ready: boolean, sleep?: number }) {
    this.id = user.id
    this.name = user.name
    this.type = user.type
    this.color = user.color
    this.status = user.status
    this.ready = user.ready
    this.sleep = user.sleep || 0

    if (user.cards) {
      this.cards = user.cards
    }
    if (user.status) {
      this.status = user.status
    }
  }
}

export { UserDTO }