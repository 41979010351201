import { WebSocketClient } from '@/modules/WebSocketClient';
import { CustomEvents } from './WebSocketClient';
import { v4 as uuid } from 'uuid';
import { Events, EventSystem, UNIVERSAL_TYPE } from './EventSystem';
import { Message } from '@/store/entities/Message';
import { userModule } from '@/store';

enum MessageType {
  GUESS_WORDS_MESSAGE = 1,
  ANSWER_WORDS_MESSAGE = 2,
  SUPER_TURN_MESSAGE = 3,
  CONTINUE_MESSAGE = 4,
  RESTART_MESSAGE = 5,
  UPGRADE_MESSAGE = 6,
  CLIENT_MESSAGE = 7,
  ROOM_INFO_MESSAGE = 8,
  FULL_INFO_MESSAGE = 9,
  DATA_INFO_MESSAGE = 10,
  VERIFY_DATA_MESSGE = 85,
  AWAKE_MESSAGE = 86
}

class SocketClient<E extends Events> extends EventSystem<E> {
  reconnectInterval: any
  verifyConnectInterval: any
  status = false

  id = ''
  clientId = uuid()
  socket: WebSocketClient<E> | null = null

  connected = false
  dead = false
  connecting = false
  lastAction: number = Date.now()
  lastPing: number = Date.now()

  hasConnection = true

  clearReconnectInterval() {
    clearTimeout(this.reconnectInterval)
  }

  clearVerifyConnectInterval() {
    clearInterval(this.verifyConnectInterval)
  }

  updateId(id: string) {
    this.id = id
    if (this.socket) {
      this.socket.id = id
    }
  }

  loop() {
    clearInterval(this.verifyConnectInterval)
    this.verifyConnectInterval = setInterval(() => {
      const socket = this.socket
      if (socket) {
        const socketConnect = socket.getSocket()
        if (socketConnect) {
          if (socketConnect.readyState !== WebSocket.OPEN) {
            console.log('connect is not opened, update')
            this.hasConnection = false
            return this.updateSocket()
          }
        }
        else {
          console.log('connect is not exist, update')
          this.hasConnection = false
          return this.updateSocket()
        }
      }
      else {
        console.log('client is not exist, update')
        this.hasConnection = false
        return this.updateSocket()
      }
    }, 2000)
  }

  init() {
    const wsUrl = process.env.VUE_APP_API_URL ?
      `${process.env.VUE_APP_API_URL.replace('http','ws')}/ws`
      : `${window.location.protocol.replace('http','ws')}//api.${window.location.host}/ws`
    this.socket = new WebSocketClient<E>(wsUrl, '', this)
    this.status = true
    this.dead = false
    this.connecting = true
  }

  updateSocket() {
    this.killSocket()
    this.clear()
    this.init()
    this.start()
  }

  upgrade({ roomId, gameId }: { roomId?: string, gameId?: string }) {
    console.log('upgrade')
    console.log(roomId, gameId, this.id)

    this.emit('upgrade', new Message({
      meta: {
        id: this.id,
        roomId: roomId || '',
        gameId: gameId || '',
        user: {
          id: userModule.id,
        },
        type: 'add'
      },
      content: {}
    }), MessageType.UPGRADE_MESSAGE)
  }

  start() {
    this.socket?.init()
    this.events.forEach((events, key) => {
      events.forEach(event => {
        this.socket?.on(key, event.callback)
      })
    })
    this.status = true
    this.dead = false
    this.loop()
  }

  updateConnect(value: boolean) {
    this.connected = value
  }

  killSocket() {
    this.socket?.clear()
    this.dead = true
    this.status = false
    this.connecting = false
    this.socket = null
  }

  close() {
    this.dead = true
  }

  emitter<K extends keyof E>(key: K, data: Parameters<E[K]>[0], type: number = UNIVERSAL_TYPE) {
    if (this.socket) {
      this.socket.emit(key, data, type)
    }
  }

  registerAction() {
    this.lastAction = Date.now()
  }

  registerPing() {
    this.lastPing = Date.now()
  }

  clear() {
    this.clearReconnectInterval()
    this.clearVerifyConnectInterval()
  }
}

const socketClient = new SocketClient<CustomEvents>()

export { socketClient, MessageType, SocketClient }
