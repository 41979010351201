import { AxiosError, AxiosRequestConfig } from "axios";
import api from "./api";

interface ErrorType {
  code: number,
  message: string
}

type Response<T = any> = {
  data?: T
  error?: ErrorType
}

class RequestError extends Error {
  constructor(error: ErrorType) {
    super()

    this.message = JSON.stringify(error)
  }
}

class Request<T = any, D = any> {
  static create<T = any, D = any>(options: AxiosRequestConfig<D>) {
    return new Request<T, D>().request<T, D>(options)
  }

  private async request<T = any, D = any>(options: AxiosRequestConfig<D>) {
    try {
      const response = await api().request<Response<T>>(options)
      const { data, error } = response.data

      if (data) {
        return {
          status: true,
          data: data
        }
      }
      else {
        throw new RequestError(error as ErrorType)
      }
    }
    catch (e) {
      const error = e as AxiosError<T, D>

      if (error.response) {
        console.error(new RequestError(error.response?.data as unknown as ErrorType))
      }
      else {
        console.error(e)
      }
    }
  }
}

export { Request }