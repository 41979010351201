class EventRecord<C extends (arg: any) => void> {
  callback: C

  constructor(callback: C) {
    this.callback = callback
  }
}

type Events<E extends {[key: string]: (arg: any) => void} = any> = {
  [K in keyof E]: E[K]
}

type TypeEvents<E extends Events> = {
  [K in keyof E]: EventRecord<E[K]>
}

const UNIVERSAL_TYPE = 0

abstract class EventSystem<E extends Events> {
  protected events: Map<keyof Events<E>, TypeEvents<E>[keyof Events<E>][]> = new Map()

  on<K extends keyof E>(key: K, callback: E[K]) {
    if (!this.events.has(key)) {
      this.events.set(key, [])
    }
    const eventsCollection = this.events.get(key)
    if (eventsCollection) {
      eventsCollection.push(new EventRecord(callback))
    }
  }

  emit<K extends keyof E>(key: K, data: Parameters<E[K]>[0], type = UNIVERSAL_TYPE) {
    this.emitter(key, data, type)
  }

  trigger<K extends keyof E>(key: K, data: Parameters<E[K]>[0]) {
    const eventsCollection = this.events.get(key)
    if (eventsCollection) {
      eventsCollection.forEach(eventRecord => {
        eventRecord.callback(data)
      })
    }
  }

  abstract emitter<K extends keyof E>(key: K, data: Parameters<E[K]>[0], type: number): void
}

export { EventSystem, Events, UNIVERSAL_TYPE }