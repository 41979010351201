import { VuexModule, Module, Mutation } from 'vuex-module-decorators';

interface Info {
  message: {
    en: string,
    ru: string
  }
}

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'AppModule'
})
class AppModule extends VuexModule {
  info: Info = {
    message: {
      ru: '',
      en: ''
    }
  }

  notify = true
  gameCrashed = false

  @Mutation
  updateInfo(data: Info) {
    this.info = data
  }

  @Mutation
  updateNotify(payload: boolean) {
    this.notify = payload
  }

  @Mutation
  updateGameCrashed(payload: boolean) {
    this.gameCrashed = payload
  }
}

export { AppModule, Info }