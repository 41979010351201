import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { socketClient } from '@/modules/SocketClient';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/static/rules",
    name: "rules",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/rules.vue"),
  },
  {
    path: "/play/prepare",
    name: "prepare",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/prepare.vue"),
  },
  // {
  //   path: "/prepare/:roomId",
  //   name: "prepare_id",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/prepare_id.vue"),
  // },
  {
    path: "/rooms",
    name: "rooms",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/rooms.vue"),
  },
  {
    path: "/game/:roomId",
    name: "game",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/game.vue"),
  },
  {
    path: "/:roomId",
    name: "room",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/room.vue"),
  },
  {
    path: "/",
    name: "index",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/index.vue"),
  },
  {
    path: "*",
    name: "404",
    beforeEnter(to, from, next) {
      next('/')
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   const pagesNames = ['room', 'game']

//   if (pagesNames.includes(to.name || '') || pagesNames.includes(from.name || '')) {
//     socketClient.updateSocket()
//   }
  
//   next()
// })

export default router;
